<template>
  <div class="w-full h-full relative flex flex-row">
    <div
      v-if="showButton"
      class="fixed bottom-4 right-4 bg-primary text-white px-4 py-4 rounded-full shadow-md cursor-pointer z-30"
      @click="scrollToTop"
    >
      <font-awesome-icon icon="arrows-up-to-line" class="text-xl" />
    </div>
    <div
      class="w-full h-full relative flex flex-col p-4 grow min-w-0 overflow-y-auto"
      ref="scrollwindow"
      v-bind:class="{
        'hidden lg:flex': detailsVisible,
        '!overflow-y-hidden':
          showApplicationDetailsPopup || showJobDetails || viewFile,
      }"
    >
      <Transition>
        <AssignToJobNotification
          :cryptKey="encryptionKey"
          :application="currentApplication"
          v-if="assignToJobNotificationVisible"
          @applicationAssigned="applicationAssigned"
          @closeNotification="assignToJobNotificationVisible = false"
        />
      </Transition>
      <Transition>
        <ChooseJobPopup
          v-if="
            showApplicationDetailsPopup &&
            !applicationDetailsApplication.id &&
            currentJob.id == 0
          "
          @jobChosen="activateJob"
          @closeNotification="showApplicationDetailsPopup = false"
        />
      </Transition>
      <Transition>
        <TopBanner :mode="bannerMode" :text="bannerText" v-if="bannerText" />
      </Transition>
      <FileViewerWrapper
        v-if="viewFile"
        :cryptKey="encryptionKey"
        :file="currentFile"
        @closeFileViewer="viewFile = false"
        @fileUpdated="applicationUpdated('files')"
      />
      <div class="relative">
        <div class="inline-block w-full">
          <span class="font-semibold text-2xl text-gray-700">Übersicht</span>
          <div
            v-if="
              !user.currentTenant ||
              (user.currentTenantData && user.currentTenantData.hr)
            "
            class="float-right px-3 cursor-pointer"
            @click="showNewJobDialog()"
          >
            <span class="text-primary text-lg float-right mx-3"
              >Neue Stelle anlegen</span
            >
            <div class="iconborder align-middle">
              <font-awesome-icon icon="plus" class="inborder" />
            </div>
          </div>
        </div>

        <div class="inline-block w-full overflow-x-auto">
          <div
            v-if="
              jobs.length == 0 &&
              (!user.currentTenant ||
                (user.currentTenantData && user.currentTenantData.hr))
            "
            class="cursor-pointer h-36 ml-4 mt-5 p-4 bg-white inline-block w-72 rounded-xl relative whitespace-normal shadow-selected"
            @click="showNewJobDialog()"
          >
            <div class="w-full h-full align-middle text-6xl">
              <font-awesome-icon icon="plus" class="inborder top-20" />
              <span class="text-xl inborder top-8">Stelle anlegen</span>
            </div>
          </div>
          <div v-else class="">
            <draggable
              :disabled="!hr"
              v-model="jobs"
              group="jobs"
              @start="drag = true"
              @end="drag = false"
              @change="persistJobOrder"
              item-key="id"
              class="hidden whitespace-nowrap auto-cols-min md:grid md:grid-rows-2 md:grid-flow-col"
            >
              <template #item="{ element }">
                <JobTile
                  :job="element"
                  :currentJob="currentJob"
                  v-on:edit="editExistingJob(element)"
                  @click="activateJob(element)"
                  @openJobDetails="activateJob(element, true)"
                />
              </template>
            </draggable>
            <div class="md:hidden whitespace-nowrap">
              <JobTile
                v-for="job in jobs"
                :key="job.id"
                :job="job"
                :currentJob="currentJob"
                v-on:edit="editExistingJob(job)"
                @click="activateJob(job)"
                @openJobDetails="activateJob(job, true)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="h-full relative">
        <div class="text-xl font-semibold text-secondary">
          Filter:
          <span
            class="text-base border-2 border-gray-300 rounded-lg font-bold px-2 py-1 bg-white shadow-md mx-1 cursor-pointer"
            :class="{
              '!border-primary text-primary': applicationFilter == 'All',
            }"
            @click="filterApplications('All')"
            >Alle</span
          ><span
            class="text-base border-2 border-gray-300 rounded-lg font-bold px-2 py-1 bg-white shadow-md mx-1 cursor-pointer"
            :class="{
              '!border-primary text-primary': applicationFilter == 'My',
            }"
            @click="filterApplications('My')"
            >Mir zugewiesen</span
          ><span
            class="text-base border-2 border-gray-300 rounded-lg font-bold px-2 py-1 bg-white shadow-md mx-1 cursor-pointer"
            :class="{
              '!border-primary text-primary': applicationFilter == 'New',
            }"
            @click="filterApplications('New')"
            >Neu</span
          >
        </div>
        <div class="grid lg:grid-cols-3 grid-cols-1 h-full">
          <div class="pr-4 pt-4 flex flex-col">
            <div class="inline-block w-full">
              <span class="text-xl font-semibold text-secondary pr-3"
                >Eingegangen</span
              >
              <div
                v-if="hr"
                class="float-right px-3 cursor-pointer align-middle mt-1"
                @click="
                  applicationDetailsApplication = false;
                  showApplicationDetailsPopup = true;
                "
              >
                <span class="text-primary mx-3 lg:hidden xl:inline"
                  >Hinzufügen</span
                >
                <div class="iconborder align-middle">
                  <font-awesome-icon icon="plus" class="inborder" />
                </div>
              </div>
            </div>
            <div
              v-if="
                applications.received.length == 0 &&
                applications.interview.length == 0 &&
                applications.offer.length == 0 &&
                currentJob.ownRole &&
                currentJob.ownRole.hr
              "
              class="cursor-pointer scale-100 p-4 inline-block w-full h-40 rounded-xl relative shadow-selected"
              @click="
                applicationDetailsApplication = false;
                showApplicationDetailsPopup = true;
              "
            >
              <div
                class="w-full h-full align-middle text-6xl relative text-center min-h-36"
              >
                <span class="text-2xl top-8">Bewerber anlegen</span><br />
                <font-awesome-icon icon="plus" class="top-20" />
              </div>
            </div>
            <draggable
              class="hidden md:inline-block h-full"
              :disabled="!hr"
              v-model="applications.received"
              group="applications"
              @start="drag = true"
              @end="drag = false"
              item-key="id"
              @change="receivedChange"
            >
              <template #item="{ element }">
                <ApplicationTile
                  :user="user"
                  :fields="user.currentTenantData.defaults.tileFields"
                  :job="currentJob"
                  @click="showDetails(true, element, true)"
                  @openComments="showDetails(true, element)"
                  :application="element"
                  :currentApplication="currentApplication"
                />
              </template>
            </draggable>
            <div class="md:hidden">
              <ApplicationTile
                :user="user"
                v-for="element in applications.received"
                :key="element.id"
                :fields="user.currentTenantData.defaults.tileFields"
                :job="currentJob"
                @click="showDetails(true, element, true)"
                @openComments="showDetails(true, element)"
                :application="element"
                :currentApplication="currentApplication"
              />
            </div>
          </div>
          <div class="pt-4 flex flex-col">
            <div class="inline-block w-full">
              <span class="text-xl font-semibold text-secondary pr-3"
                >Vorstellungsgespräch</span
              >
            </div>
            <draggable
              class="hidden md:inline-block h-full"
              :disabled="!hr"
              v-model="applications.interview"
              group="applications"
              @start="drag = true"
              @end="drag = false"
              item-key="id"
              @change="interviewChanged"
            >
              <template #item="{ element }">
                <ApplicationTile
                  :user="user"
                  :fields="user.currentTenantData.defaults.tileFields"
                  :job="currentJob"
                  @click="showDetails(true, element, true)"
                  @openComments="showDetails(true, element)"
                  :application="element"
                  :currentApplication="currentApplication"
                />
              </template>
            </draggable>
            <div class="md:hidden">
              <ApplicationTile
                :user="user"
                v-for="element in applications.interview"
                :key="element.id"
                :fields="user.currentTenantData.defaults.tileFields"
                :job="currentJob"
                @click="showDetails(true, element, true)"
                @openComments="showDetails(true, element)"
                :application="element"
                :currentApplication="currentApplication"
              />
            </div>
          </div>
          <div class="pt-4 flex flex-col">
            <div class="inline-block w-full">
              <span class="text-xl font-semibold text-secondary">Angebot</span>
            </div>
            <draggable
              class="hidden md:inline-block h-full"
              :disabled="!hr"
              v-model="applications.offer"
              group="applications"
              @start="drag = true"
              @end="drag = false"
              item-key="id"
              @change="offerChange"
            >
              <template #item="{ element }">
                <ApplicationTile
                  :user="user"
                  :fields="user.currentTenantData.defaults.tileFields"
                  :job="currentJob"
                  @click="showDetails(true, element, true)"
                  @openComments="showDetails(true, element)"
                  :application="element"
                  :currentApplication="currentApplication"
                />
              </template>
            </draggable>
            <div class="md:hidden">
              <ApplicationTile
                :user="user"
                v-for="element in applications.offer"
                :key="element.id"
                :fields="user.currentTenantData.defaults.tileFields"
                :job="currentJob"
                @click="showDetails(true, element, true)"
                @openComments="showDetails(true, element)"
                :application="element"
                :currentApplication="currentApplication"
              />
            </div>
          </div>
        </div>
      </div>

      <Transition>
        <JobDetails
          v-if="showJobDetails"
          :newJob="jobDialogShowsNewJob"
          :applications="applications"
          :job="currentJob"
          :user="user"
          :cryptKey="encryptionKey"
          @jobSaved="jobSaved"
          @closeJobDetails="showJobDetails = false"
        />
      </Transition>
      <Transition>
        <ApplicationDetailsPopup
          v-if="
            showApplicationDetailsPopup &&
            ((currentJob && currentJob.id != 0) ||
              (currentApplication && currentApplication.id))
          "
          :jobs="jobs"
          :currentJob="currentJob"
          :currentApplication="applicationDetailsApplication"
          :cryptKey="encryptionKey"
          @sentCancelMail="deleteNotificationVisible = true"
          @applicationUpdated="applicationUpdated"
          @applicationSaved="applicationSaved"
          @closeApplicationDetailsPopup="showApplicationDetailsPopup = false"
        />
      </Transition>
      <Transition>
        <NoTenantNotification
          v-if="noTenantNotificationVisible"
          @closeNotification="noTenantNotificationVisible = false"
        />
      </Transition>
      <Transition>
        <NoCreatePermissionNotification
          v-if="noCreatePermissionNotificationVisible"
          @closeNotification="noCreatePermissionNotificationVisible = false"
        />
      </Transition>
      <Transition>
        <ConfirmNotification
          :application="currentApplication"
          :mode="confirmMode"
          :cryptKey="cryptKey"
          v-if="confirmNotificationVisible"
          @applicationUpdated="applicationUpdated"
          @closeNotification="confirmNotificationVisible = false"
        />
      </Transition>
    </div>
    <Transition>
      <DeleteNotification
        :application="currentApplication"
        v-if="deleteNotificationVisible"
        @applicationUpdated="applicationUpdated"
        @closeNotification="deleteNotificationVisible = false"
      />
    </Transition>
    <CommentSidebar
      ref="detailbar"
      class="w-full lg:w-1/4"
      :application="currentApplication"
      :currentJob="currentJob"
      :hr="hr"
      :cryptKey="encryptionKey"
      v-if="detailsVisible && currentApplication"
      @closeDetails="detailsVisible = false"
      @sentCancelMail="deleteNotificationVisible = true"
      @showPopup="
        detailsVisible = false;
        showDetails(true, currentApplication, true);
      "
      @deleteOrArchiveApplication="deleteNotificationVisible = true"
      @assignApplication="assignToJobNotificationVisible = true"
      @fileReceived="fileReceived"
      @applicationUpdated="applicationUpdated"
      @banner="showBanner"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import draggable from "vuedraggable";
import ApplicationTile from "@/components/tiles/ApplicationTileV2.vue";
import JobTile from "@/components/tiles/JobTileV2.vue";
import CommentSidebar from "@/components/CommentSidebar.vue";
import JobDetails from "@/components/JobDetails.vue";
import TopBanner from "@/components/TopBanner.vue";
import ApplicationDetailsPopup from "@/components/ApplicationDetailsPopup.vue";
import FileViewerWrapper from "@/components/FileViewerWrapper.vue";
import NoTenantNotification from "@/components/notifications/NoTenantNotification.vue";
import AssignToJobNotification from "@/components/notifications/AssignToJobNotification.vue";
import DeleteNotification from "@/components/notifications/DeleteNotification.vue";
import NoCreatePermissionNotification from "@/components/notifications/NoCreatePermissionNotification.vue";
import API from "@/API/Business";
import { useCookies } from "vue3-cookies";
import ConfirmNotification from "@/components/notifications/ConfirmNotification.vue";
import ChooseJobPopup from "@/components/notifications/ChooseJobPopup.vue";
import Util from "@/util/strings";
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  name: "LoginView",
  components: {
    draggable,
    ApplicationTile,
    JobTile,
    JobDetails,
    TopBanner,
    ApplicationDetailsPopup,
    FileViewerWrapper,
    CommentSidebar,
    NoCreatePermissionNotification,
    NoTenantNotification,
    DeleteNotification,
    ConfirmNotification,
    AssignToJobNotification,
    ChooseJobPopup,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      applicationFilter: "All",
      allApplications: {
        received: [],
        interview: [],
        offer: [],
      },
      hr: false,
      bannerText: "",
      bannerMode: "success",
      encryptionKey: "",
      drag: false,
      detailsVisible: false,
      assignToJobNotificationVisible: false,
      noTenantNotificationVisible: false,
      deleteNotificationVisible: false,
      noCreatePermissionNotificationVisible: false,
      confirmNotificationVisible: false,
      confirmMode: "cancel",
      viewFile: false,
      currentFile: {},
      jobDialogShowsNewJob: true,
      showJobDetails: false,
      showApplicationDetailsPopup: false,
      showEditJob: false,
      editJob: {
        id: false,
        title: "",
        link: "",
        salaryFrom: "",
        salaryTo: "",
        targetDate: "",
      },
      showAddApplication: false,
      newApplicationName: "",
      newJobTitle: "",
      currentApplication: {},
      applicationDetailsApplication: {},
      currentJob: {},
      applications: {
        received: [],
        interview: [],
        offer: [],
      },
      requiredDocuments: {},
      jobs: [],
      showButton: false,
    };
  },

  methods: {
    scrollToTop() {
      this.$refs.scrollwindow.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleScroll() {
      if (this.$refs.scrollwindow.scrollTop > 200) {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
    },
    showBanner(text, error = false) {
      this.bannerMode = error ? "error" : "success";
      this.bannerText = text;
      let vm = this;
      setTimeout(() => {
        vm.bannerText = "";
      }, 5000);
    },
    fileReceived(file) {
      this.currentFile = file;
      this.viewFile = true;
      //let ct = "text/plain";
      //if (Object.keys(contenttypes).includes(file.name.split(".")[-1])) {
      //  ct = contentypes[file.name.split(".")[-1]];
      //}
      //      //window.open("data:"+ct+";base64,"+file.data,"_blank").focus()
    },
    jobSaved(clear = false) {
      this.getJobs(clear);
      this.showJobDetails = false;
    },

    applicationUpdated(type = "update", close = true) {
      let vm = this;
      if(close){
        this.showApplicationDetailsPopup = false
      }
      if (["status", "files"].includes(type) || !close) {
        this.getApplications(this.currentApplication.id);
      } else if (
        type == "deleted" ||
        type == "archive" ||
        type == "talentpool" ||
        type == "employed"
      ) {
        if (type == "deleted") {
          vm.showBanner("Bewerber wurde gelöscht");
        } else if (type == "archive") {
          vm.showBanner("Bewerber wurde archiviert");
        } else if (type == "talentpool") {
          vm.showBanner("Bewerber wurde Talentpool hinzugefügt");
        } else if (type == "employed") {
          vm.showBanner("Mitarbeiter wurde archiviert");
        }
        this.getApplications();
      }
    },
    filterApplications(filter) {
      this.applicationFilter = filter;
      this.getApplications();
    },
    applicationAssigned(jobId) {
      this.activateJob(this.jobs.filter((item) => item.id == jobId)[0]);
      this.showBanner("Bewerbung verschoben");
    },
    applicationSaved(close = true) {
      if (close) {
        this.showApplicationDetailsPopup = false;
        this.getApplications();
      } else {
        this.getApplications(this.currentApplication.id);
      }
    },
    changeStatus(e, status) {
      if (e.added != undefined) {
        e.added.element.status = status;
        API.updateApplication(
          e.added.element,
          this.encryptionKey,
          e.added.element.id,
          () => {}
        );
        this.persistOrder();
      } else if (e.moved != undefined) {
        this.persistOrder();
      }
    },
    persistOrder() {
      let order = [];
      let i = 0;
      this.applications.received.forEach((application) => {
        let a = {};
        a.id = application.id;
        a.order = i;
        i = i + 1;
        order.push(a);
      });
      this.applications.interview.forEach((application) => {
        let a = {};
        a.id = application.id;
        a.order = i;
        i++;
        order.push(a);
      });
      this.applications.offer.forEach((application) => {
        let a = {};
        a.id = application.id;
        a.order = i;
        i++;
        order.push(a);
      });
      API.updateApplicationOrder(this.currentJob, order);
    },
    persistJobOrder() {
      let order = [];
      let i = 0;
      this.jobs.forEach((job) => {
        let j = {};
        j.id = job.id;
        j.order = i;
        i = i + 1;
        order.push(j);
      });
      API.updateJobOrder(order);
    },
    offerChange(e) {
      this.changeStatus(e, "Angebot");
    },
    receivedChange(e) {
      this.changeStatus(e, "Eingegangen");
    },
    interviewChanged(e) {
      this.changeStatus(e, "Interview");
    },
    showDetails(show, element, popup = false) {
      this.currentApplication = element;
      if (!this.detailsVisible && popup) {
        this.applicationDetailsApplication = element;
        this.showApplicationDetailsPopup = true;
        this.detailsVisible = !show;
      } else if (!this.showApplicationDetailsPopup) {
        this.detailsVisible = show;
      }
    },
    checkHR() {
      this.hr = this.user.currentTenantData.hr;
      if (!this.hr && this.currentJob) {
        this.hr = this.currentJob.permissions.filter(
          (el) => el.user == this.user.mail
        )[0].hr;
      }
    },
    activateJob(job, showDetails = false) {
      this.requiredDocuments = this.user.currentTenantData.defaults.documents;

      if (this.currentJob != job) {
        this.currentJob = job;
        this.checkHR();
        history.pushState({}, null, "/b/board?j=" + job.id);
        this.getApplications();
      }
      if (showDetails) {
        this.jobDialogShowsNewJob = false;
        this.showJobDetails = true;
      }
    },
    showNewJobDialog() {
      if (this.user.currentTenant) {
        if (
          this.user.currentTenantData &&
          (this.user.currentTenantData.hr || this.user.currentTenantData.admin)
        ) {
          this.jobDialogShowsNewJob = true;
          this.showJobDetails = true;
        } else {
          this.noCreatePermissionNotificationVisible = true;
        }
      } else {
        this.noTenantNotificationVisible = true;
      }
    },
    getJobs(clear = false) {
      let vm = this;
      if (clear) {
        this.applications = {
          received: [],
          interview: [],
          offer: [],
        };
      }
      API.getJobs((jobs) => {
        let allApplications = 0;
        jobs.forEach((job) => {
          if (!this.user.currentTenantData.hr) {
            job.ownRole = job.permissions.filter(
              (el) => el.user == vm.user.mail
            )[0];
          }
          allApplications += job.applications;
        });
        vm.jobs = [
          {
            id: 0,
            title: "Alle Stellen",
            applications: allApplications,
            requiredFields: { cover: true, cv: true, qualifications: true },
          },
        ].concat(jobs);
        if ((!vm.currentJob || clear) && jobs.length > 0) {
          if (vm.$route.query.j) {
            this.activateJob(
              vm.jobs.filter((item) => item.id == vm.$route.query.j)[0]
            );
          } else {
            this.activateJob(vm.jobs[0]);
          }
        }
      });
    },
    getApplications(keepId = false) {
      let vm = this;
      if(!this.currentJob || this.currentJob.id == undefined) {
        return
      }
      //vm.detailsVisible = false;
      API.getApplicationsForJob(
        this.currentJob,
        this.encryptionKey,
        (applications) => {
          applications.forEach((app, i, a) => {
            if (app.startDate)
              a[i].startDatePretty = Util.formatDate(app.startDate);
            if (app.scheduled)
              a[i].scheduledPretty = Util.formatDate(app.scheduled);
          });
          vm.allApplications.received = applications.filter(
            (item) =>
              item.status === "Eingegangen" || item.status === "Abgesagt"
          );
          vm.allApplications.interview = applications.filter(
            (item) => item.status === "Interview"
          );
          vm.allApplications.offer = applications.filter(
            (item) => item.status === "Angebot"
          );
          vm.applications.received = vm.allApplications.received.filter(
            (application) => {
              if (vm.applicationFilter == "All") {
                return true;
              } else if (vm.applicationFilter == "My") {
                return application.currentTask == vm.user.mail;
              } else if (vm.applicationFilter == "New") {
                return (
                  application.status == "Eingegangen" &&
                  application.daysInStatus < 3
                );
              }
            }
          );
          vm.applications.interview = vm.allApplications.interview.filter(
            (application) => {
              if (vm.applicationFilter == "All") {
                return true;
              } else if (vm.applicationFilter == "My") {
                return application.currentTask == vm.user.mail;
              } else if (vm.applicationFilter == "New") {
                return (
                  application.status == "Eingegangen" &&
                  application.daysInStatus < 3
                );
              }
            }
          );
          vm.applications.offer = vm.allApplications.offer.filter(
            (application) => {
              if (vm.applicationFilter == "All") {
                return true;
              } else if (vm.applicationFilter == "My") {
                return application.currentTask == vm.user.mail;
              } else if (vm.applicationFilter == "New") {
                return (
                  application.status == "Eingegangen" &&
                  application.daysInStatus < 3
                );
              }
            }
          );
          vm.detailsVisible = false;
          if (keepId || vm.$route.query.a) {
            let id = keepId ? keepId : vm.$route.query.a;
            delete vm.$route.query.a;
            history.pushState({}, null, "/b/board");
            vm.currentApplication = applications.filter(
              (item) => item.id == id
            )[0];
            vm.showDetails(true, vm.currentApplication, true);
          } else {
            vm.currentApplication = null;
          }
        }
      );
    },
    editExistingJob(job) {
      this.editJob = job;
      this.showEditJob = true;
    },
    init() {
      if (this.user.currentTenant) {
        this.encryptionKey = localStorage.getItem(
          "encryptionKey" + this.user.currentTenant
        );
        if (
          this.user.currentTenantData.billing &&
          !this.user.currentTenantData.hr
        ) {
          window.location = "/b/org?billing";
        } else if (
          this.encryptionKey == null &&
          this.user.currentTenant != "addNewTenant"
        ) {
          if (
            this.user.currentTenantData &&
            this.user.currentTenantData.passHash == "1712500753"
          ) {
            this.encryptionKey = "applyhrnopasswordused";
            localStorage.setItem(
              this.encryptionKey + this.currentTenant,
              "applyhrnopasswordused"
            );
          } else {
            window.location = "/b/org?encryption";
          }
        }
        this.getJobs(true);
      }
    },
  },
  watch: {
    currentTenant(newUser, oldUser) {
      this.init();
    },
  },
  computed: {
    currentTenant() {
      return this.user.currentTenant;
    },
  },
  created: function () {
    this.init();
  },
  mounted() {
    this.$refs.scrollwindow.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    this.$refs.scrollwindow.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style scoped></style>