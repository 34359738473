<template>
  <div class="w-full h-full relative p-4 overflow-y-auto">
    <file-viewer v-if="viewFile" :file="file" @closeFileViewer="viewFile = false" />
    <span class="font-semibold text-2xl text-primary border-b-2 border-primary mr-3" >Organisationseinstellungen</span><span onclick="window.location='/b/settings'" class=" cursor-pointer font-semibold text-2xl text-gray-700 border-b-2 ">Persönliche Einstellungen</span><button v-if="!create" class="text-red-500 border-red-500 px-5 py-1 font-bold float-right hidden" @click="deleteOrg()">
      Löschen</button><button v-if="!create" class="text-yellow-500 mr-5 border-yellow-500 px-5 py-1 font-bold float-right hidden" @click="deleteOrg()">
      Verlassen
    </button>
    <div class="pt-4 sm:p-5 h-full ">
      <div v-if="!create">
        <span class="inline-block font-bold text-4xl border-b-2 border-gray-600 mr-3">{{ currentTenantData.name }}</span>
        <span v-if="currentTenant.billing || currentTenantData.admin" class="bg-primary rounded-full px-2 py-1 whitespace-nowrap inline-block mt-3">Verbleibendes Guthaben: {{ currentTenantData.discount }} €</span>
      </div>
      <div class="w-full h-full md:flex md:flex-row " v-bind:class="[create ? 'justify-center' : '']">
        <div class="pt-4 sm:pt-10" v-if="!create">
          <span @click="mode = 'billing'" v-if="currentTenantData.billing" v-bind:class="[mode == 'billing' ? 'text-primary underline' : '']" class="mt-3 cursor-pointer block bold text-xl">Rechnungen</span>
          <span @click="mode = 'team'" v-if="currentTenantData.admin" v-bind:class="[mode == 'team' ? 'text-primary underline' : '']" class="mt-3 cursor-pointer block bold text-xl">Team</span>
          <span @click="mode = 'fields'" v-if="currentTenantData.admin" v-bind:class="[mode == 'fields' ? 'text-primary underline' : '']" class="mt-3 cursor-pointer block bold text-xl">Standardfelder</span>
          <span @click="mode = 'key'" v-if="currentTenantData.hr || !currentTenantData.billing" v-bind:class="[mode == 'key' ? 'text-primary underline' : '']" class="mt-3 cursor-pointer block bold text-xl">Verschlüsselung</span>
          <span @click="mode = 'custom'" v-if="currentTenantData.admin" v-bind:class="[mode == 'custom' ? 'text-primary underline' : '']" class="mt-3 cursor-pointer block bold text-xl">Öffentliche Stellen</span>
          <span @click="mode = 'mailbox'" v-if="currentTenantData.admin" v-bind:class="[mode == 'mailbox' ? 'text-primary underline' : '']" class="mt-3 cursor-pointer block bold text-xl">Postfach</span>
        </div>
        <div class="text-left grow relative bg-gray-100 md:pl-4">
          <div v-if="create || (mode == 'billing' && currentTenantData.billing)" class="sm:inline-block align-top pt-4 text-left">
            <span v-if="!create" class="text-2xl font-bold">Rechnungsanschrift</span>
            <div v-else>
              <input v-model="currentTenantData.name" name="companyName" class="text-2xl bg-transparent w-96 text-center" placeholder="Name des Unternehmens" />
            </div>
            <br />
            <div class="inline-block bg-white rounded-2xl shadow-lg w-96 px-3 py-4 mt-3 mr-3 text-left">
              <div class="inline-block w-full p-1">
                <label v-bind:class="{
      'text-red-500': showErr && !currentTenantData.billingName,
    }">Ansprechpartner</label>
                <input id="billingName" class="w-full" name="name" @change="updateTenant()" v-model="currentTenantData.billingName" />
              </div>
              <div class="inline-block w-full p-1">
                <label v-bind:class="{
      'text-red-500': showErr && errors.billingMail,
    }">E-Mail</label>
                <input id="billingMail" class="w-full" @change="updateTenant()" v-model="currentTenantData.billingMail" />
              </div>
              <div class="inline-block w-2/3 p-1">
                <label v-bind:class="{
      'text-red-500': showErr && !currentTenantData.billingStreet,
    }">Straße</label>
                <input id="street" class="w-full" @change="updateTenant()" v-model="currentTenantData.billingStreet" />
              </div>
              <div class="inline-block w-1/3 p-1">
                <label v-bind:class="{
      'text-red-500': showErr && !currentTenantData.billingNumber,
    }">Nr</label>
                <input id="billingNumber" class="w-full" type="number" @change="updateTenant()" v-model="currentTenantData.billingNumber" />
              </div>
              <div class="inline-block w-1/2 p-1">
                <label v-bind:class="{
      'text-red-500':
        showErr && !currentTenantData.billingPostcode,
    }">Postleitzahl</label>
                <input id="postcode" class="w-full" @change="updateTenant()" v-model="currentTenantData.billingPostcode" />
              </div>
              <div class="inline-block w-1/2 p-1">
                <label v-bind:class="{
      'text-red-500': showErr && !currentTenantData.billingCity,
    }">Stadt</label>
                <input id="billingCity" class="w-full" @change="updateTenant()" v-model="currentTenantData.billingCity" />
              </div>
              <div class="inline-block w-full p-1">
                <label>Land</label>
                <input id="billingCountry" class="w-full" @change="updateTenant()" v-model="currentTenantData.billingCountry" disabled />
              </div>
              <div class="inline-block w-full p-1">
                <label>Umsatzsteuer-ID</label>
                <input id="billingTaxId" class="w-full" @change="updateTenant()" v-model="currentTenantData.billingTaxId" />
              </div>
            </div>
            <br />
            <button v-if="create && currentTenantData.name" class="mt-5 px-10" v-bind:class="{
      'border-green-500': checkErrors() == 0,
      'text-green-500': checkErrors() == 0,
    }" @click="createTenant()" @mouseover="checkErrors(true)">
              Erstellen
            </button>
          </div>
          <div v-if="mode == 'billing' && currentTenantData.billing" class="inline-block align-top pt-4 text-left">
            <span class="text-2xl font-bold">Rechnungen</span><br />
            <span>Aktueller Monat:
              {{ currentTenantData.currentMonthBalance }} €</span>
            <div class="h-96  text-left">
              <div class="py-3" v-for="invoice in currentTenantData.invoices" :key="invoice">
                <InvoiceTile :invoice="invoice" @openInvoice="openInvoice" />
              </div>
            </div>
          </div>
          <div id="teamdiv" v-if="mode == 'team' && currentTenantData.admin" class="inline-block align-top pt-4 w-5/6 text-left">
            <span class="inline-block text-2xl font-bold">Team (Berechtigungen)</span>

            <div class="inline-block bg-white rounded-2xl shadow-lg w-full px-3 py-4 mt-3">
              <div class="hidden lg:inline-block w-full">
              <span class="inline-block font-bold text-xl w-2/5">E-Mail</span>
              <span class="inline-block font-bold text-xl w-1/2 mx-2">Rollen</span>
            </div>
            <div class="lg:hidden inline-block w-full">
              <span class="inline-block font-bold text-xl">E-Mail & Rollen</span>
            </div>
            <div class="iconborder align-middle" @click="addTeamMember()">
              <font-awesome-icon icon="plus" class="inborder" />
            </div>
            <div v-for="role in currentTenantData.roles" :key="role" style="margin-top: 20px">
              <div v-if="!role.id || role.admin || role.hr || role.billing">
                <div class="inline-block w-full lg:w-2/5">
                  <span v-if="role.id" class="font-bold text-secondary">{{
      role.mail
    }}</span>
                  <input v-else class="font-bold text-secondary w-full" placeholder="E-Mail" v-model="role.mail" />
                </div>
                <div class="inline-block w-full lg:w-1/2">
                  <span v-bind:class="[
      role.admin ? 'roleselected' : 'roledeselected',
    ]" @click="toggleMemberRole(role, 'admin')">Admin</span>
                  <span v-bind:class="[
      role.billing ? 'roleselected' : 'roledeselected',
    ]" @click="toggleMemberRole(role, 'billing')">Billing</span>
                  <span v-bind:class="[
      role.hr ? 'roleselected' : 'roledeselected',
    ]" @click="toggleMemberRole(role, 'hr')">HR</span>
                  <div class="inline-block">
                    <button v-if="role.id && role.mail != user.mail" class="border-primary text-primary mx-2 px-2 py-1" @click="removeMember(role)">
                      Entfernen
                    </button>
                    <button v-else-if="!role.id" class="border-secondary text-secondary mx-2 px-2 py-1 mt-1 xl:mt-0" @click="fixMember(role)">
                      Hinzufügen
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5">
            <span class="inline-block text-2xl font-bold">Ansprechpartner für Bewerbungen</span>

            <div class="inline-block bg-white rounded-2xl shadow-lg w-full p-2 mt-3">
              <div class="w-full  p-3">

                <span class="font-semibold text-xl">Vor- und Nachname</span>
                <input class="w-full bg-gray-100 p-3 mt-2" placeholder="Vor- und Nachname" v-model="currentTenantData.defaults.contact.contact_name" />
              </div>
              <div class="grid md:grid-cols-2">
                <div class="pt-6 p-3">

                  <span class="block font-semibold text-xl">E-Mail</span>
                  <input class="w-full bg-gray-100 p-3 mt-2" placeholder="E-Mail" v-model="currentTenantData.defaults.contact.contact_mail" />
                </div>
                <div class="pt-6  p-3">
                  <span class="block font-semibold text-xl">Telefonnummer</span>
                  <input class="w-full bg-gray-100 p-3 mt-2" placeholder="Telefonnummer" v-model="currentTenantData.defaults.contact.contact_phone" />
                </div>
              </div>
              <button @click="saveDefaults()" v-bind:class="{ 'text-green-500 border-green-500': saved }" class="float-right text-primary border-primary mb-3 mt-5 mr-5">
                Speichern
              </button>

            </div>
          </div>
        </div>
        <PublicJobs v-if="mode == 'custom' && currentTenantData.admin && user" :user="user" @refreshUser="$emit('refreshUser')" />
        <div id="keydiv" v-if="mode == 'key' &&
      !create &&
      (currentTenantData.hr || !currentTenantData.billing)
      " class="inline-block align-top pt-4 w-full sm:w-5/6 text-left">
          <span class="inline-block text-2xl font-bold">Verschlüsselung</span><br />
          <div class="bg-white rounded-lg p-4 mt-3 inline-block w-full">
            <span class="mb-3 inline-block">Das Verschlüsselungspasswort wird zur Verschlüsselung Ihrer
              Daten genutzt und ist uns unbekannt. Deshalb haben wir keinen
              unverschlüsselten Zugriff auf Ihre Daten.</span><br />
            <div v-if="storedPassword">
              <span>Ihr Passwort ist hinterlegt. Klicke Sie auf das Auge, um
                es sichtbar zu machen.</span><br />
              <div class="flex flex-row mt-5 bg-gray-100 border-b border-black lg:w-96">

                <input class="inline-block border-none no-underline" v-model="password" />
                <font-awesome-icon class="ml-3 text-secondary cursor-pointer mt-5 mr-3" size="lg" icon="eye" @click="password = storedPassword" />
              </div>
            </div>

            <div v-else-if="currentTenantData.passHash == ''">
              <div v-if="currentTenantData.admin">
                <div v-if="confirmNoPassword">
                  <span class="text-red-500 font-bold mt-5 inline-block">Sie sind dabei, für Ihre Organisation kein Passwort festzulegen. Das erhöht die Nutzerfreundlichkeit, allerdings hätten wir so die Möglichkeit, auf Ihre Daten
                    zuzugreifen. Das werden wir nicht tun, es wäre aber technisch möglich.
                    Sie müssten auch Ihre Bewerber darauf hinweisen, dass Sie ihre Daten auf Applyhr verarbeiten, um 100% datenschutzkonform zu sein.</span>
                  <div class="w-full text-center mt-5">
                    <button class="border-red-500 text-red-500 font-bold mr-2" @click="setPassword(true)">Wirklich ohne Passwort fortfahren</button>
                    <button class="border-green-500 mt-2" @click="confirmNoPassword = false">Zurück zur Passworteingabe</button>
                  </div>
                </div>
                <div v-else>
                  <span>Ihre Organisation hat noch kein Passwort festgelegt.
                    Erstellen Sie das Passwort unten und klicken Sie auf
                    bestätigen, um zu beginnen.<br /></span>
                  <span class="text-red-500 font-bold mt-5 inline-block">ACHTUNG: Ihre Daten werden mit diesem Passwort
                    verschlüsselt. Applyhr kennt dieses Passwort nicht. Wenn Sie
                    das Passwort verlieren, können Ihre bisher gespeicherten
                    Daten nicht mehr entschlüsselt werden. BEWAHREN SIE ES
                    SICHER AUF!</span>
                  <br /><input class="mt-5 w-72" type="text" v-model="password" placeholder="Verschlüsselungspasswort" />
                  <button @click="setPassword()" class="ml-3 mt-3 sm:mt-0">Bestätigen</button><br />
                  <div class="w-full pt-3 text-center ">
                    <button @click="confirmNoPassword = true" class=" border-red-500 text-red-500">Ohne Passwort fortfahren</button>
                  </div>
                  <div class="w-72 text-center">
                    <div v-bind:class="[
      password.length > 6 ? 'bg-green-300' : 'bg-gray-300',
    ]" class="w-20 h-2 inline-block"></div>
                    <div v-bind:class="[
      password.length > 12 ? 'bg-green-300' : 'bg-gray-300',
    ]" class="ml-2 w-20 h-2 inline-block"></div>
                    <div v-bind:class="[
      password.length > 18 ? 'bg-green-300' : 'bg-gray-300',
    ]" class="ml-2 w-20 h-2 inline-block"></div>
                  </div>
                </div>
              </div>
              <div v-else>
                <span class="text-red-500 font-bold mt-5 inline-block">Ihre Organisation hat noch kein Passwort festgelegt. Bitten
                  Sie einen Adminstrator, dies zu tun.</span>
              </div>
            </div>

            <div v-else>
              <span>Ihre Organisation hat für die Daten ein Passwort festgelegt,
                welches bei Ihnen noch nicht hinterlegt sind. Fragen Sie die
                Person, die Sie eingeladen hat, nach diesem Passwort und
                hinterlegen Sie es unten.</span><br />
              <input name="password" class="mt-5 w-full lg:w-72" type="text" v-model="password" placeholder="Verschlüsselungspasswort" />
              <div class="text-right lg:text-left mt-2 lg:mt-0 lg:inline-block">
                <button @click="setStoredPassword()" class="ml-3">
                  Speichern
                </button>
              </div>
              <span v-if="errormsg" class="text-red-500 m-5 text-lg">{{
      errormsg
    }}</span>
            </div>
          </div>
        </div>
        <div id="fielddiv" v-if="mode == 'fields' && !create" class="inline-block align-top pt-4 w-5/6 h-full text-left overflow-y-auto">
          <span class="inline-block text-2xl font-bold">Interne Felder</span><br />
          <div class="bg-white rounded-lg p-4 mt-3 inline-block">
            <span class="mb-3 inline-block">Hier kann ausgewählt werden, welche Infos auf einen Blick
              sichtbar sein sollen. Alle anderen Informationen werden nach
              Auswählen des Bewerbers in der Detailsleiste sichtbar.
            </span>
            <div class="grid lg:grid-cols-2 mt-5">
              <div>
                <div>
                  <div class="grid lg:grid-cols-2">
                    <div>
                      <input id="startDate" type="checkbox" @click="saveDefaults()" v-model="currentTenantData.defaults.tileFields.startDate" /><label for="startDate" class="text-lg">Startdatum</label>
                    </div>
                    <div>
                      <input id="weekhours" type="checkbox" @click="saveDefaults()" v-model="currentTenantData.defaults.tileFields.weekhours" /><label for="weekhours" class="text-lg">Wochenstunden</label>
                    </div>
                    <div>
                      <input id="documents" type="checkbox" @click="saveDefaults()" v-model="currentTenantData.defaults.tileFields.documents" /><label for="documents" class="text-lg">Dokumente</label>
                    </div>
                    <div>
                      <input id="birthdate" type="checkbox" @click="saveDefaults()" v-model="currentTenantData.defaults.tileFields.birthdate" /><label for="birthdate" class="text-lg">Geburtsdatum</label>
                    </div>
                    <div>
                      <input id="city" type="checkbox" @click="saveDefaults()" v-model="currentTenantData.defaults.tileFields.city" /><label for="city" class="text-lg">Wohnort</label>
                    </div>
                    <div>
                      <input id="comments" type="checkbox" @click="saveDefaults()" v-model="currentTenantData.defaults.tileFields.comments" /><label for="comments" class="text-lg">Kommentare</label>
                    </div>
                    <div>
                      <input id="salary" type="checkbox" @click="saveDefaults()" v-model="currentTenantData.defaults.tileFields.salary" /><label for="salary" class="text-lg">Gehaltsvorstellung</label>
                    </div>
                  </div>
                </div>

              </div>
              <ApplicationTileV2 :fields="tileFields" :application="application" :job="job" class="inline-block" />
            </div>
          </div>
          <span class="mt-5 inline-block text-2xl font-bold">Daten für veröffentlichte Stellen</span>
          <div class="bg-white rounded-lg p-4 mt-3 inline-block">
            <span class="mb-3 inline-block">Hier kann ausgewählt werden, welche Infos von Bewerbern
              abgefragt werden, die sich auf eine über Applyhr veröffentlichte
              Stelle bewerben. Diese Felder können pro Stelle angepasst
              werden.
            </span>
            <span class="text-lg font-bold">Allgemeine Pflichtangaben</span>
            <div class="grid grid-cols-2">
              <div>
                <input id="askPhone" type="checkbox" @click="saveDefaults()" v-model="currentTenantData.defaults.requiredJobFields.phone" /><label for="askPhone" class="text-lg">Telefonnummer</label>
              </div>
              <div>
                <input id="askstartDate" type="checkbox" @click="saveDefaults()" v-model="currentTenantData.defaults.requiredJobFields.startDate" /><label for="askstartDate" class="text-lg">Startdatum</label>
              </div>
              <div>
                <input id="askweekhours" type="checkbox" @click="saveDefaults()" v-model="currentTenantData.defaults.requiredJobFields.weekhours" /><label for="askweekhours" class="text-lg">Wochenstunden</label>
              </div>
              <div>
                <input id="asksalary" type="checkbox" @click="saveDefaults()" v-model="currentTenantData.defaults.requiredJobFields.salary" /><label for="asksalary" class="text-lg">Gehaltsvorstellung</label>
              </div>
              <div>
                <input id="askbirthdate" type="checkbox" @click="saveDefaults()" v-model="currentTenantData.defaults.requiredJobFields.birthdate" /><label for="askbirthdate" class="text-lg">Geburtsdatum</label>
              </div>
              <div>
                <input id="askcity" type="checkbox" @click="saveDefaults()" v-model="currentTenantData.defaults.requiredJobFields.city" /><label for="askcity" class="text-lg">Wohnort</label>
              </div>
            </div>
            <div class="mt-5">
              <span class="text-lg font-bold">Erforderliche Dokumente</span>
              <div class="grid grid-cols-2">
                <div>
                  <input id="askCover" type="checkbox" @click="saveDefaults()" v-model="currentTenantData.defaults.requiredJobFields.cover" /><label for="askCover" class="text-lg">Anschreiben</label>
                </div>
                <div>
                  <input id="askCV" type="checkbox" @click="saveDefaults()" v-model="currentTenantData.defaults.requiredJobFields.cv" /><label for="askCV" class="text-lg">Lebenslauf</label>
                </div>
                <div>
                  <input id="askQualificatios" type="checkbox" @click="saveDefaults()" v-model="currentTenantData.defaults.requiredJobFields.qualifications" /><label for="askQualificatios" class="text-lg">Qualifikationen</label>
                </div>

              </div>
            </div>
            <div class="mt-5">
              <span class="text-lg font-bold">Folgende Daten werden immer abgefragt:</span>
              <div>
                <input id="cover" class="disabled" type="checkbox" checked disabled /><label for="name" class="text-lg">Name (Pflicht)</label>
              </div>
              <div>
                <input id="cover" class="disabled" type="checkbox" checked disabled /><label for="name" class="text-lg">E-Mailadresse (Pflicht)</label>
              </div>
              <div>
                <input id="misc" class="disabled" type="checkbox" checked disabled /><label for="misc" class="text-lg">Sonstige Dokumente</label>
              </div>
            </div>
          </div>
        </div>
        <MailboxIntegration :user="user" v-if="mode == 'mailbox'" />
      </div>
    </div>
  </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ApplicationTileV2 from "@/components/tiles/ApplicationTileV2.vue";
import { useCookies } from "vue3-cookies";
import API from "@/API/Business";
import Encryption from "@/Encryption";
import FileViewer from "@/components/FileViewer.vue";
import InvoiceTile from "@/components/tiles/InvoiceTile.vue";
import PublicJobs from "@/components/business/PublicJobs.vue";
import MailboxIntegration from "@/components/business/MailboxIntegration.vue";

export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  name: "OrganizationView",
  components: {
    PublicJobs,
    InvoiceTile,
    ApplicationTileV2,
    FileViewer,
    MailboxIntegration
  },
  props: {
    user: Object,
  },
  data() {
    return {
      file: {},
      viewFile: false,
      showErr: false,
      errors: {},
      tileFields: {},
      askfields: {
        startDate: true,
        weekhours: true,
        salary: true,
        cv: true,
        qualifications: true,
      },
      job: {
        requiredFields: {}
      },
      application: {
        name: "Max Mustermann",
        mail: "m.mustermann@applyhr.de",
        phone: "01234/5678910",
        showDate: "Sofort",
        comments: 3,
        birthdate: "1997-06-01",
        weekhours: 40,
        city: "Köln",
        salary: 105000,
        files: [],
        jobdata: {
          requiredFields: {}
        }
      },
      documents: {
        initial: true,
        cover: true,
        cv: true,
        qualifications: true,
      },
      selected: {},
      errormsg: "",
      create: true,
      currentTenantData: { billingCountry: "Deutschland" },
      password: "",
      storedPassword: "",
      mode: "billing",
      newName: "",
      newmember: {
        mail: "",
        role: {
          admin: false,
          billing: false,
          hr: true,
        },
      },
      saved: false,
      confirmNoPassword: false
    };
  },
  methods: {
    openInvoice: function (id) {
      let vm = this;
      if (navigator.pdfViewerEnabled) {
        API.getFile("invoices/" + id, "", (file) => {
          if (file) {
            vm.file = file;
            vm.viewFile = true;
          }
        });
      } else {
        window.open(
          process.env.VUE_APP_BUSINESS_URL +
          "/files/invoices/" +
          id +
          "?static=1"
        );
      }
    },
    setPassword(defaultPW = false) {
      if (defaultPW) {
        this.password = "applyhrnopasswordused"
      }
      if (this.password.trim().length > 0) {
        let pwHash = Encryption.hashString(this.password);
        API.updateTenant(
          { id: this.user.currentTenant, passHash: pwHash },
          (success) => {
            this.currentTenantData.passHash = pwHash;
            this.storedPassword = this.password;
            this.password = "****************";
            this.confirmNoPassword = false;
            localStorage.setItem(
              "encryptionKey" + this.user.currentTenant,
              this.storedPassword
            );
          }
        );
      }
    },
    selectTenant(company) {
      this.selected = company;
    },
    checkErrors(display = false) {
      let required = [
        "billingName",
        "billingMail",
        "billingStreet",
        "billingNumber",
        "billingPostcode",
        "billingCity",
        "billingCountry",
      ];
      required.forEach((req) => {
        if (
          this.currentTenantData[req] == undefined ||
          this.currentTenantData[req].toString().trim() == ""
        ) {
          this.errors[req] = true;
        } else if (
          req == "billingMail" &&
          !this.currentTenantData[req].includes("@")
        ) {
          this.errors[req] = true;
        } else {
          delete this.errors[req];
        }
      });
      if (display) {
        this.showErr = true;
      }
      return Object.keys(this.errors).length;
    },
    createTenant() {
      let vm = this;
      this.checkErrors(true);
      if (this.checkErrors(true) == 0) {
        API.addTenant(this.currentTenantData, (tenant) => {
          history.pushState({}, null, vm.$route.path);
          vm.$emit("tenantCreated");
        });
      }
    },
    updateTenant() {
      if (this.currentTenantData.id) {
        API.updateTenant(this.currentTenantData, (tenant) => { });
      }
    },
    deleteOrg() { },
    getTenants() { },
    toggleMemberRole(member, role) {
                  if (member.mail == this.user.mail) return;
      if (role == "admin" && !member.admin) {
        member.admin = true;
        member.billing = true;
        member.hr = true;
      } else {
        member[role] = !member[role];
        if (!member.admin && !member.hr && !member.billing && !member.id) {
          this.removeMember(member);
        } else if (member.id) {
          API.updateTenantRole(this.currentTenant, member, () => { });
        }
      }
    },
    addTeamMember() {
      this.currentTenantData.roles.push({
        mail: "",
        role: {
          admin: false,
          billing: false,
          hr: false,
        },
      });
    },
    getStoredPassword() {
      this.storedPassword = localStorage.getItem(
        "encryptionKey" + this.currentTenant
      );
      if (this.storedPassword) {
        this.password = "**********************";
      }
    },
    setStoredPassword() {
      if (
        String(Encryption.hashString(this.password)) !==
        this.currentTenantData.passHash
      ) {
                        this.errormsg = "Das Passwort ist falsch.";
        setTimeout(() => {
          this.errormsg = "";
        }, 2000)
      } else {
        this.errormsg = "";
        localStorage.setItem(
          "encryptionKey" + this.currentTenant,
          this.password
        );
        this.storedPassword = this.password;
        this.password = "***************************";
      }
    },
    fixMember(member) {
      let vm = this;
      API.addTenantRole(this.currentTenant, member, (newMember) => {
        if (newMember) {
          vm.currentTenantData.roles[
            vm.currentTenantData.roles.indexOf(member)
          ] = newMember;
        }
      });
    },
    removeMember(member) {
      this.currentTenantData.roles.splice(
        this.currentTenantData.roles.indexOf(member),
        1
      );
      if (member.id) {
        API.deleteTenantRole(this.currentTenant, member, (status) => { });
      }
    },
    init() {
      if (this.user.currentTenantData) {
        this.currentTenantData = this.user.currentTenantData;
      }
      if (
        this.currentTenant == "addNewTenant" ||
        !this.user.currentTenantData
      ) {
        this.create = true;
      } else {
        this.getStoredPassword();
        this.create = false;
        this.tileFields = this.user.currentTenantData.defaults.tileFields;
        this.job.requiredFields = this.user.currentTenantData.defaults.requiredJobFields
        if (
          !this.user.currentTenantData.billing &&
          !this.user.currentTenantData.admin
        )
          this.mode = "key";
      }
    },
    saveDefaults() {
      this.saved = true;
      setTimeout(() => {
        API.saveDefaults(
          this.currentTenantData.defaults,
          (s) => { }
        );
      }, 500)

    },
  },
  watch: {
    currentTenant() {
      this.init();
    },
  },
  computed: {
    currentTenant() {
      return this.user.currentTenant;
    },
    fieldsLeft() {
      return (
        6 -
        Object.keys(this.tileFields).filter((k) => {
          return this.tileFields[k];
        }).length
      );
    },
  },
  created: function () {
    if (this.user.currentTenant) this.init();
    if (this.$route.query.encryption !== undefined) {
      this.mode = "key";
          } else if (
      this.$route.query.fields !== undefined &&
      this.currentTenantData.admin
    ) {
      this.mode = "fields";
    } else if (
      (this.$route.query.billing !== undefined &&
        this.currentTenantData.billing) || (this.currentTenantData.billing && !this.currentTenantData.hr)
    ) {
      this.mode = "billing";
    } else if (this.currentTenantData.admin) {
      this.mode = "billing";
    } else {
      this.mode = "key"
    }
  },
};
</script>
<style scoped>
input {
  border-bottom: 1px solid black;
}
</style>