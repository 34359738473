<template >
  <div ref="modalbg" class="fixed sm:pl-64 pt-20 sm:pt-24 z-30 center w-full h-full sm:px-10 bg-black/50" @click="checkClosing($event)">
    <FileViewer :file="file" :cryptKey="cryptKey" @closeFileViewer="$emit('closeFileViewer')" @fileUpdated="$emit('fileUpdated')" />
  </div>
</template>

<script>
import FileViewer from "./FileViewer.vue";

export default {
  props: {
    file: Object,
    cryptKey: String,
  },
  components: {
    FileViewer
  },
  data() {
    return {
      showEdit: false,
      editableFile: {}
    };
  },
  methods: {
    checkClosing(event) {
      if (event.target == this.$refs.modalbg) {
        this.$emit("closeFileViewer");
      }
    },
  },
  emits: ["closeFileViewer", "fileUpdated"],
};
</script>

