<template >
    <div class="
        bg-white
        w-full
        h-full
        lg:rounded-3xl
        overflow-y-auto
        p-5
        flex flex-col
        basis-0
      ">
      <div>
        <span v-if="editableFile.name" class="font-bold text-xl inline-block mb-4">{{ editableFile.name }}</span>
        <span v-else class="font-bold text-xl inline-block mb-4">{{ editableFile.originalName }}</span>
        <div @click="$emit('closeFileViewer')" class="iconborder mt-2 inline-block">
          <font-awesome-icon icon="xmark" class="inborder" />
        </div>
        <div class="inline-block whitespace-nowrap">
        <button v-if="!showEdit" @click="showEdit = true" class="ml-4">Bearbeiten</button>
        <button v-if="!editableFile.encrypted" @click="encryptFile" class="ml-1 mb-2 bad">Verschlüsseln</button></div>
        <div v-if="showEdit" class="mb-4">
          <div class="grid grid-cols-2"></div>
          <div>
            <label>Dateiname</label>
            <input type="text" v-model="editableFile.name" />
          </div>
          <div>
            <label class="block mt-3">Dokumentenart</label>
            <select v-model="editableFile.type" class="bg-inherit focus:outline-none pr-3 w-auto">
              <option class="" value="">Bitte auswählen</option>
              <option value="Anschreiben">Anschreiben</option>
              <option value="Lebenslauf">Lebenslauf</option>
              <option value="Qualifikationen">Qualifikationen</option>
              <option value="Sonstiges">Sonstiges</option>
            </select>
            <button @click="saveFile" class="ml-3">Speichern</button>
          </div>

        </div>


      </div>
      <div v-if="!watchable" class="text-center pt-20 font-bold">
        <span>Diese Datei kann nicht angezeigt werden.<br>Du kannst die Datei stattdessen herunterladen.</span><br>
        <button @click="download" class="ml-3 mt-10">Herunterladen</button>
      </div>
      <iframe v-else class="w-full grow" ref="modalcontent" :src="url"> </iframe>
      <a :href="url" id="showInNewTab" target="_blank" :download="file.originalName" hidden></a>
    </div>
</template>

<script>
import Encryption from '@/Encryption';
import API from "@/API/Business";
let contenttypes = {
  ovpn: "text/plain",
  pdf: "application/pdf",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  png: "image/png",
};

export default {
  props: {
    file: Object,
    cryptKey: String,
  },
  data() {
    return {
      showEdit: false,
      editableFile: {},
      watchable: true
    };
  },
  emits: ["closeFileViewer", "fileUpdated"],
  methods: {
    download() {
      let ct = "text/plain";
      let fileType = "unknown";
      if (this.file.originalName) {
        fileType = this.file.originalName.split(".").slice(-1)[0];
      }
      if (Object.keys(contenttypes).includes(fileType)) {
        ct = contenttypes[fileType];
      }
      const link = document.createElement('a');
      // create a blobURI pointing to our Blob
      link.href = window.URL.createObjectURL(Encryption.b64toBlob(this.file.data, ct));
      link.download = this.file.name;
      // some browser needs the anchor to be in the doc
      document.body.append(link);
      link.click();
      link.remove();
    },
    encryptFile() {
      let vm=this;
      API.updateFile(this.file.id, this.editableFile, this.cryptKey,(s)=>{
        if (s) {
          vm.$emit("fileUpdated")
          vm.editableFile.encrypted=true
        }
      })
    },
    saveFile() {
      this.showEdit = false
      let vm = this
      API.updateFile(this.editableFile.id, {
        name: this.editableFile.name,
        type: this.editableFile.type
      }, this.cryptKey,
        (s) => {
          if (s) {
            vm.$emit("fileUpdated", this.editableFile)
          }
        });
    }
  },
  computed: {
    
    url: function () {
      if (!this.file.data) return "";
      let ct = "text/plain";
      let fileType = "unknown";
      if (this.file.originalName) {
        fileType = this.file.originalName.split(".").slice(-1)[0];
      }
      if (Object.keys(contenttypes).includes(fileType)) {
        ct = contenttypes[fileType];
      }
      return window.URL.createObjectURL(Encryption.b64toBlob(this.file.data, ct));
    },
  },
  watch: {
    file: function () {
      this.editableFile = JSON.parse(JSON.stringify(this.file))
    }
  },
  created: function () {
    let filetype="xxx"
    if (this.file.originalName) {
      filetype = this.file.originalName.split(".").slice(-1)[0];
    }
    this.editableFile = JSON.parse(JSON.stringify(this.file))
    if(!navigator.pdfViewerEnabled || !Object.keys(contenttypes).includes(filetype)) {
      this.watchable=false
    }
    //if (!navigator.pdfViewerEnabled) {
    //  let vm = this;
    //  setTimeout(() => {
    //    window.open(process.env.VUE_APP_PRIVATE_URL + "/files/static/" + vm.file.id)
    //    vm.$emit("closeFileViewer")
    //  }, 200);
    //}
  },
};
</script>

<style scoped></style>
