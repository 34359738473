<template>
  <div
    ref="modalbg"
    class="z-30 absolute top-0 left-0 w-full h-full pl-20 text-center py-10 bg-black/50"
    @click="checkClosing($event)"
  >
  <div class="w-full h-full relative">
    <div
      ref="modalcontent"
      class="z-40 center w-1/2 text-left bg-white rounded-3xl"
    >
      <div class="w-full h-full relative inline-block">
        <div
          @click="$emit('closeNotification')"
          class="iconborder-modal z-50"
        >
          <font-awesome-icon size="lg" icon="xmark" class="inborder" />
        </div>
        <div class="w-full h-full p-8">
          <div class="w-full text-center">
            <span class="font-bold text-3xl">Fehlende Berechtigungen</span>
          </div>
          <div class="text-left mt-3 inline-block">
            <span class="">Du hast nicht die Berechtigungen, um in diesem Unternehmen eine Stelle anzulegen. Bitte einen Adminstrator, dir entweder die "Ersteller" oder die "Admin"-Rolle zu geben.</span>
          </div>
          <div class="mt-5 w-full text-center">
            <button class="border-primary text-primary" @click="$emit('closeNotification')">Verstanden</button>
          </div>
        </div>
      </div>
    </div>
    <input type="file" id="newApplicationFileUpload" @change="prepareUploadFile()" hidden />
  </div>
  </div>
</template>

<script>
import { useCookies } from "vue3-cookies";

export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  props: {
    currentJob: Object,
  },
  data() {
    return {
    }
  },
  methods: {
    checkClosing(event) {
            if (event.target == this.$refs.modalbg) {
        this.$emit("closeNotification");
      }
    },
  },
  created: function () {
    
  },
};
</script>

<style scoped>
</style>
