<template>
  <NavBar
    v-if="user"
    :user="user"
    @navigate="navigate"
    class="hidden sm:block"
  />
  <SearchBarPrivate v-if="user" :user="user" :searchEntries="searchEntries" :slug="$route.params.slug" />
  <div
    class="
      sm:pl-56
      w-full
      fixed
      top-0
      pt-20
      bottom-0
      text-text-color
      box-border
      flex flex-row
      bg-gray-100
    "
    tabindex="0"
    v-on:keyup.esc="escPressed()"
  >

    <Dashboard v-if="$route.name == 'Dashboard'" :user="user" />
    <TenantOverview v-if="$route.name == 'TenantOverview'" />
    <Documents v-if="$route.name == 'Documents'" />
    <TenantJobsView v-else-if="user && $route.params.slug && !$route.params.jobId" :user="user" :slug="$route.params.slug" />
    <TenantJobsView v-else-if="user && $route.params.slug" :user="user" :slug="$route.params.slug" />
  </div>
</template>
<script>
// @ is an alias to /src
import TenantJobsView from "@/views/private/TenantJobsView.vue";
import NavBar from "@/components/private/NavBar.vue";
import SearchBarPrivate from "@/components/private/SearchBar.vue"
import Dashboard from "@/views/private/Dashboard.vue"
import TenantOverview from "@/views/private/TenantOverview.vue"
import Documents from "@/views/private/Documents.vue"
import { useCookies } from "vue3-cookies";
import PrivateAPI from "@/API/Private";
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  name: "PrivateMainView",
  components: {
    TenantJobsView,
    SearchBarPrivate,
    Dashboard,
    Documents,
    TenantOverview,
    NavBar,
  },
  data() {
    return {
      user: null,
      slug: false,
      searchEntries: []
    };
  },
  methods: {
    updateUser(user){
      this.user=user
    },
    navigate(path) {
      history.pushState({}, null, path);
      this.$router.push(path);
    },
    getPrivateUser() {
      let vm = this;
      PrivateAPI.getUser((user) => {
        if (user) {
          vm.user = user;
        } else if (vm.$route.path.startsWith("/c/")) {
          vm.cookies.set("redirect", vm.$route.fullPath.trim());
          window.location = "/login";
        }
        else{
          vm.user={
            "name":"Gastbenutzer"
          }
        }
        if (vm.$route.path == "/c/") {
          window.location = "/c/overview";
        }
      });
    },
    getSearchEntries(){
      let slug="applicationoverview"
      if(this.$route.params.slug){
        slug=this.$route.params.slug
      }
      PrivateAPI.getSearchEntries(slug,entries=>{
        for(let i=0;i<entries.length;i++){
          let keys=Object.keys(entries[i])
          entries[i].strings=""
          keys.forEach(key=>{
            entries[i].strings+=entries[i][key]
          })
        }
        this.searchEntries=entries
              })
    }
  },
  created: function () {
    this.getPrivateUser();
    this.getSearchEntries();
  },
};
</script>