<template>
  <div
    ref="modalbg"
    class="z-30 absolute top-0 left-0 w-full h-full text-center py-10 bg-black/50"
    @click="checkClosing($event)"
  >
    <div class="w-full h-full relative">
      <div
        ref="modalcontent"
        class="z-40 center w-11/12 sm:w-1/2 2xl:w-1/4 text-left bg-white rounded-3xl"
      >
        <div class="w-full h-full relative inline-block">
          <div
            @click="$emit('closeNotification')"
            class="iconborder-modal z-50"
          >
            <font-awesome-icon size="lg" icon="xmark" class="inborder" />
          </div>
          <div class="w-full h-full p-1 sm:p-8">
            <div class="w-full text-center">
              <span class="font-bold text-2xl"
                >{{ application.name }} einem Job zuweisen</span
              >
              <div class="mt-8">
                <input
                  v-model="filter"
                  class="block border-b-2 mb-2"
                  placeholder="Filter nach Titel"
                />
                <div class="overflow-y">
                  <span v-if="filteredJobs.length == 0" class="text-red-500"
                    >Keine Jobs gefunden</span
                  >
                  <button
                    class="m-1"
                    v-for="job in filteredJobs"
                    :key="job"
                    v-bind:class="{ positive: job == currentJob }"
                    @click="currentJob = job"
                  >
                    {{ job.title }}
                  </button>
                </div>
              </div>
              <div class="mt-5">
                <button v-if="currentJob" class="positive" @click="confirm()">
                  Zuweisen
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <input
        type="file"
        id="newApplicationFileUpload"
        @change="prepareUploadFile()"
        hidden
      />
    </div>
  </div>
</template>

<script>
import { useCookies } from "vue3-cookies";
import API from "@/API/Business";

export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  props: {
    application: Object,
    mode: String,
    cryptKey: String,
  },
  data() {
    return {
      filter: "",
      jobs: [],
      currentJob: false,
    };
  },
  methods: {
    confirm() {
      let status = this.application.status;
      if (this.application.archived) status = "Eingegangen";
      API.updateApplication(
        { archived: false, status: status, job:  this.currentJob.id },
        this.cryptKey,
        this.application.id,
        () => {
          this.$emit("applicationAssigned", this.currentJob.id);
          this.$emit("closeNotification");
        }
      );
    },
    checkClosing(event) {
            if (event.target == this.$refs.modalbg) {
        this.$emit("closeNotification");
      }
    },
  },
  computed: {
    filteredJobs: function () {
      return this.jobs.filter((job) => job.title.includes(this.filter));
    },
  },
  created: function () {
    let vm = this;
    API.getJobs((jobs) => {
      vm.jobs = jobs;
    });
  },
};
</script>

<style scoped>
</style>
