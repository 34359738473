<template>
  <div class="w-full h-full relative flex flex-row">
    <div
      class="w-full h-full relative flex flex-col p-4 grow min-w-0 overflow-y-auto"
      ref="scrollwindow"
      v-bind:class="{
        'hidden lg:flex': detailsVisible,
        '!overflow-y-hidden':
          showApplicationDetailsPopup || showJobDetails || viewFile,
      }"
    >
      <Transition>
        <TopBanner :mode="bannerMode" :text="bannerText" v-if="bannerText" />
      </Transition>
      <FileViewer
        v-if="viewFile"
        :file="currentFile"
        @closeFileViewer="viewFile = false"
      />
      <div class="relative">
        <div class="inline-block w-full">
          <span class="font-semibold text-2xl text-gray-700">Archiv</span>
        </div>
        <div class="inline-block w-full overflow-x-auto"></div>
      </div>
      <div class="p-4">
        <span class="font-bold text-xl">Suchen:</span
        ><input
          v-model="filter"
          class="text-xl w-1/3 ml-2 border-b-2 bg-inherit"
          placeholder="Name / E-Mail / Job / Telefonnummer"
        />
        <span
          v-if="filteredApplications.length == 0"
          class="ml-2 font-bold text-red-400"
          >Keine Bewerber gefunden</span
        >
      </div>
      <div>
        <span class="font-semibold text-xl text-gray-700">Eingestellt</span>
        <div class="overflow-y-auto">
          <template v-for="application in employed" :key="application">
            <ApplicationTile
              :user="user"
              :application="application"
              :currentApplication="currentApplication"
              :fields="user.currentTenantData.defaults.tileFields"
              :documents="user.currentTenantData.defaults.documents"
              class="w-128 inline-block"
              @click="showDetails(true, application)"
            />
          </template>
        </div>
      </div>
      <div>
        <span class="font-semibold text-xl text-gray-700">Talentpool</span>
        <div class="overflow-y-auto">
          <template v-for="application in talentpool" :key="application">
            <ApplicationTile
              :user="user"
              :application="application"
              :currentApplication="currentApplication"
              :fields="user.currentTenantData.defaults.tileFields"
              :documents="user.currentTenantData.defaults.documents"
              class="w-128 inline-block"
              @click="showDetails(true, application)"
            />
          </template>
        </div>
      </div>
      <div>
        <span class="font-semibold text-xl text-gray-700">Archiviert</span>
        <div class="overflow-y-auto">
          <template v-for="application in archive" :key="application">
            <ApplicationTile
              :user="user"
              :application="application"
              :currentApplication="currentApplication"
              :fields="user.currentTenantData.defaults.tileFields"
              :documents="user.currentTenantData.defaults.documents"
              class="w-128 inline-block"
              @click="showDetails(true, application)"
            />
          </template>
        </div>
      </div>
    </div>
    <Transition name="slide-fade">
      <DetailsSidebar
        ref="detailbar"
        :mode="'archive'"
        :application="currentApplication"
        :cryptKey="encryptionKey"
        :hr="false"
        v-if="detailsVisible && currentApplication"
        @closeDetails="detailsVisible = false"
        @applicationUpdated="applicationUpdated"
        @fileReceived="fileReceived"
        @assignApplication="assignToJobNotificationVisible = true"
        @deleteApplication="deleteNotificationVisible = true"
      />
    </Transition>
    <Transition>
      <DeleteNotification
        :cryptKey="encryptionKey"
        :application="currentApplication"
        v-if="deleteNotificationVisible"
        @applicationUpdated="applicationUpdated"
        @closeNotification="deleteNotificationVisible = false"
      />
    </Transition>
    <Transition>
      <AssignToJobNotification
        :cryptKey="encryptionKey"
        :application="currentApplication"
        v-if="assignToJobNotificationVisible"
        @applicationAssigned="applicationAssigned"
        @closeNotification="assignToJobNotificationVisible = false"
      />
    </Transition>
  </div>
</template>

<script>
// @ is an alias to /src
import ApplicationTile from "@/components/tiles/ApplicationTileV2.vue";
import DeleteNotification from "@/components/notifications/DeleteNotification.vue";
import AssignToJobNotification from "@/components/notifications/AssignToJobNotification.vue";
import DetailsSidebar from "@/components/DetailsSidebar.vue";
import FileViewer from "@/components/FileViewer.vue";
import BusinessAPI from "@/API/Business";
import { useCookies } from "vue3-cookies";
import TopBanner from "@/components/TopBanner.vue";

export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  name: "LoginView",
  components: {
    ApplicationTile,
    FileViewer,
    DetailsSidebar,
    DeleteNotification,
    AssignToJobNotification,
    TopBanner,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      encryptionKey: "",
      bannerMode: "success",
      bannerText: "",
      detailsVisible: false,
      currentApplication: {},
      applications: [],
      viewFile: false,
      currentFile: false,
      deleteNotificationVisible: false,
      assignToJobNotificationVisible: false,
      filter: "",
    };
  },

  methods: {
    showBanner(text, error = false) {
      this.bannerMode = error ? "error" : "success";
      this.bannerText = text;
      let vm = this;
      setTimeout(() => {
        vm.bannerText = "";
      }, 5000);
    },
    getArchivedApplications() {
      let vm = this;
      BusinessAPI.getArchivedApplications(
        this.encryptionKey,
        (applications) => {
          vm.applications = applications;
          vm.applications.forEach((application, i, a) => {
            a[i].strings = "";
            a[i].strings += application.name;
            a[i].strings += application.jobtitle;
            a[i].strings += application.phone;
            a[i].strings += application.city;
            a[i].strings += application.mail;
            a[i].strings = application.strings.toLowerCase();
          });
          if (vm.$route.query.a) {
            vm.showDetails(
              true,
              vm.applications.filter((item) => item.id == vm.$route.query.a)[0]
            );
            delete vm.$route.query.a;
            history.pushState({}, null, "/b/archive");
          }
        }
      );
    },
    fileReceived(file) {
      this.currentFile = file;
      this.viewFile = true;
    },
    showDetails(show, element) {
      this.detailsVisible = show;
      this.currentApplication = element;
    },
    applicationUpdated() {
      this.getArchivedApplications();
    },
    applicationAssigned() {
      this.applications.splice(
        this.applications.indexOf(this.currentApplication),
        1
      );
      this.showBanner("Bewerber zugewiesen");
    },
    init() {
      if (this.user.currentTenant) {
        this.encryptionKey = localStorage.getItem(
          "encryptionKey" + this.user.currentTenant
        );
        if (
          this.encryptionKey == null &&
          this.currentTenant != "addNewTenant"
        ) {
          window.location = "/b/org?encryption";
        } else {
          this.getArchivedApplications();
        }
      }
    },
  },
  watch: {
    currentTenant(newUser, oldUser) {
      this.init();
    },
  },
  computed: {
    currentTenant() {
      return this.user.currentTenant;
    },
    filteredApplications: function () {
      let vm = this;
      return this.applications.filter((application) => {
        let fp = vm.filter.split(" ");
        for (let i = 0; i < fp.length; i++) {
          if (
            !(
              application.strings &&
              application.strings.includes(fp[i].toLowerCase())
            )
          ) {
            return false;
          }
        }
        return true;
      });
    },
    employed: function () {
      return this.filteredApplications.filter(
        (application) => application.employed
      );
    },
    talentpool: function () {
      return this.filteredApplications.filter(
        (application) => application.talentpool
      );
    },
    archive: function () {
      return this.filteredApplications.filter(
        (application) => !application.talentpool
      );
    },
  },
  created: function () {
    this.init();
  },
};
</script>
<style scoped>
.sortable-ghost {
  opacity: 0.3;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all 4s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
</style>