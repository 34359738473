<template>
<vue-qrious
    value="https:/test.de"
    @change="onDataUrlChange"
  hidden/>
  <a hidden></a>
</template>
  

<script>
import VueQrious from 'vue-qrious'
export default {
  name: "LoginView",
  components: {
    VueQrious,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      
    };
  },

  methods: {
    
  },
  watch: {
    
  },
  computed: {
    
  },
  created: function () {
      },
};
</script>
<style scoped>
.sortable-ghost {
  opacity: 0.3;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all 4s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
</style>