<template>

  <div
    ref="modalbg"
    class="z-10 center w-full h-full bg-black/50 text-center lg:pt-5 lg:pb-5"
    @click="checkClosing($event)"
  >  <AddFileModal
    v-if="showAddFileModal"
    :kind="newFileKind"
    :files="files"
    @closeModal="showAddFileModal = false"
    @addFile="addFile"
  />
    <div
      ref="modalcontent"
      class="z-20 w-full lg:w-2/3 bg-white lg:rounded-3xl p-8 inline-block text-left relative h-full overflow-y-scroll break-all pb-10"
    >
      <div class="w-full overflow-y-auto inline-block">
        <div @click="$emit('closeJobDetails')" class="iconborder-modal z-40">
          <font-awesome-icon size="lg" icon="xmark" class="inborder" />
        </div>
        <div class="w-full rounded overflow-y-auto">
          <div
          class=" bg-white pb-5 w-full text-center z-30"
          >
            <div>
              <span class="font-bold text-3xl">Neue Bewerbung</span><br />
            </div>
          </div>
          <div id="content" class="mb-5 pt-14">
            <div class="grid md:grid-cols-2">
              <span
                v-if="companyNameMissing"
                class="font-semibold text-lg text-red-500 pt-3"
                >Unternehmen (Pflichtfeld)</span
              >
              <span v-else class="font-semibold text-lg pt-3">Unternehmen:</span>
              <input
                placeholder="Applyhr GmbH"
                v-model="application.companyName"
                class="w-11/12 bg-gray-100 p-3 mt-2 text-left"
              />
            </div>
            <span class="font-semibold text-xl pt-3">Ansprechpartner:</span>
            <div class="grid md:grid-cols-2">
              <div class="pt-2">
                <span class="block font-semibold text-lg">Name</span>
                <input
                  class="w-11/12 bg-gray-100 p-3 mt-2"
                  placeholder="Max Personaler"
                  v-model="application.contact_name"
                />
              </div>
              <div class="pt-2">
                <span class="block font-semibold text-lg">Telefonnummer</span>
                <input
                  class="w-11/12 bg-gray-100 p-3 mt-2"
                  placeholder="01234 / 56789"
                  v-model="application.contact_phone"
                />
              </div>
              <div class="pt-2">
                <span class="block font-semibold text-lg">E-Mail</span>
                <input
                  class="w-11/12 bg-gray-100 p-3 mt-2"
                  placeholder="jobs@firma.de"
                  v-model="application.contact_mail"
                />
              </div>
              <div class="pt-2">
                <span class="block font-semibold text-lg">Website</span>
                <input
                  class="w-11/12 bg-gray-100 p-3 mt-2"
                  placeholder="https://applyhr.de/"
                  v-model="application.website"
                />
              </div>
            </div>
            <span class="font-semibold text-xl pt-3">Sonstiges:</span>
            <div class="grid md:grid-cols-2">
              <div class="pt-2">
                <span class="block font-semibold text-lg">Jobtitel</span>
                <input
                  class="w-11/12 bg-gray-100 p-3 mt-2"
                  placeholder="Verkäufer"
                  v-model="application.jobtitle"
                />
              </div>
              <div class="pt-2">
                <span class="block font-semibold text-lg"
                  >Link zur Stellenausschreibung</span
                >
                <input
                  class="w-11/12 bg-gray-100 p-3 mt-2"
                  placeholder="https://stepstone.de/job/123456"
                  v-model="application.weblink"
                />
              </div>
            </div>
            <div id="apply" class="overflow-y-auto min-h-xs mt-4">
              <div>
                <span class="inline-block md:hidden text-2xl font-bold pb-4"
                  >Deine Daten</span
                >
                <div class="pt-2">
                  <span class="font-semibold text-xl">Dokumente hochladen</span>
                  <div class="overflow-y-auto max-h-32 mt-2">
                    <div
                      v-for="file in application.files"
                      :key="file.id"
                      class="mt-2 relative"
                    >
                      <div class="w-full overflow-hidden">
                        <span
                          class="border-b-2 border-secondary cursor-pointer"
                          @click="getFile(file)"
                          >{{ file.originalName }}</span
                        >
                        <div class="float-right">
                          <span class="text-secondary">{{
                            file.documentType
                          }}</span>

                          <font-awesome-icon
                            icon="trash"
                            @click="deleteFile(file)"
                            class="pr-1 px-2 text-secondary cursor-pointer"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <input
                    type="file"
                    id="newApplicationFileUpload"
                    @change="prepareUploadFile()"
                    hidden
                  />
                  <div class="grid md:grid-cols-2">
                    <div
                      
                      class="inline-block cursor-pointer mt-3"
                      @click="showFilePicker('Anschreiben')"
                    >
                      <div
                        class="iconborder-p inline-block"
                        style="float: unset"
                      >
                        <font-awesome-icon icon="plus" class="inborder" />
                      </div>
                      <span
                        class="ml-3 align-middle text-secondary font-semibold"
                        >Anschreiben hinzufügen</span
                      >
                    </div>
                    <div
                     
                      class="inline-block cursor-pointer mt-3 w-"
                      @click="showFilePicker('Lebenslauf')"
                    >
                      <div
                        class="iconborder-p inline-block"
                        style="float: unset"
                      >
                        <font-awesome-icon icon="plus" class="inborder" />
                      </div>
                      <span
                        class="ml-3 align-middle text-secondary font-semibold"
                        >Lebenslauf hinzufügen</span
                      >
                    </div>
                    <div
                      
                      class="inline-block cursor-pointer mt-3"
                      @click="showFilePicker('Qualifikation')"
                    >
                      <div
                        class="iconborder-p inline-block"
                        style="float: unset"
                      >
                        <font-awesome-icon icon="plus" class="inborder" />
                      </div>
                      <span
                        class="ml-3 align-middle text-secondary font-semibold"
                        >Qualifikationen hinzufügen</span
                      >
                    </div>
                    <div
                      class="inline-block cursor-pointer mt-3"
                      @click="showFilePicker('Sonstiges')"
                    >
                      <div
                        class="iconborder-p inline-block"
                        style="float: unset"
                      >
                        <font-awesome-icon icon="plus" class="inborder" />
                      </div>
                      <span
                        class="ml-3 align-middle text-secondary font-semibold"
                        >Sonstiges Dokument hinzufügen</span
                      >
                    </div>
                  </div>
                  <div class="mt-5">
                    <span class="font-semibold text-xl">Kommentar</span>
                    <textarea
                      v-model="application.comment"
                      class="w-full mt-2 h-24 overflow-y-auto bg-gray-100 p-2 resize-none"
                      placeholder="Schreib einen Kommentar..."
                    ></textarea>
                  </div>
                </div>
                <div class="mt-5 w-full text-center">
                  <button @click="submitApplication()" class="relative mx-2">
                    <div
                      v-if="loading"
                      class="lds-dual-ring overflow-hidden"
                    ></div>
                    <span v-bind:class="[loading ? 'text-white' : '']"
                      >Bewerbung speichern</span
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/API/Private";
import { useCookies } from "vue3-cookies";
import AddFileModal from "../AddFileModal.vue";

export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  components: { AddFileModal },
  expose: ["someMethod"],
  emits: ["closeJobDetails", "applicationSaved"],
  props: {
    user: Object,
  },
  data() {
    return {
      waitFor: 0,
      showApplication: false,
      application: {
        companyName: "",
        files: [],
        encrypted: false,
        comment: "",
      },
      currentUploadFileType: "",
      companyNameMissing: false,
      loading: false,
      files: {},
      showAddFileModal: false,
      newFileKind: "Anschreiben",
    };
  },
  methods: {
    checkClosing(event) {
      if (event.target == this.$refs.modalbg) {
        this.$emit("closeJobDetails");
      }
    },
    submitApplication() {
      let vm = this;
      if(this.application.companyName.trim() == ""){
        this.companyNameMissing=true
        return
      }
      this.loading = true;
      API.addApplication(this.application, (application) => {
        if (application) {
          vm.$emit("applicationSaved");
        } else {
                            }
      });
    },
    showFilePicker(filetype) {
      this.newFileKind = filetype;
      this.showAddFileModal = true;
    },
    addFile(file) {
      this.showAddFileModal = false;
      this.application.files.push(file);
    },
    deleteFile(file) {
      this.application.files.splice(this.application.files.indexOf(file), 1);
    },
    taskDone() {
      if (this.waitFor <= 1) {
        this.$emit("applicationSaved");
      } else {
        this.waitFor--;
      }
    },
  },
  created: function () {
    let vm = this;
    API.getFiles((files) => {
      vm.files = files;
    });
  },
};
</script>

<style scoped>
</style>
