<template>
  <div class="px-1 sm:px-3 py-3 inline-block w-fit">
    <div
      id="tile"
      v-bind:class="[job == currentJob ? 'shadow-selected' : 'shadow-lg', job.title=='Alle Stellen'?'!w-40':'']"
      class="
        cursor-pointer
        p-4
        bg-white
        inline-block
        sm:w-96
        w-full
        rounded-xl
        relative
        whitespace-normal
        align-top"
    >
      <div>
        <span class="text-xl font-medium leading-none">{{ job.title }}</span>
        <div class="ml-2 rounded-full bg-primary/25 py-0.5 px-3 hidden">
          <span class="text-primary">New</span>
        </div>
        <div v-if="showFields.details && !['Postfach-Integration','Alle Stellen'].includes(job.title)" class="iconborder" @click="$emit('openJobDetails')">
          <font-awesome-icon icon="ellipsis" class="inborder" />
        </div>

      </div>
      <div v-if="showFields.showDate && !['Postfach-Integration','Alle Stellen'].includes(job.title)" class="mt-4 px-1 inline-block min-w-1/2">
        <font-awesome-icon icon="forward-step" size="lg" />
        <span v-if="job.showDate" class="ml-5 align-middle">{{ job.showDate }}</span>
        <span v-else class="ml-5 align-middle">Sofort</span>
      </div>
      <div v-if="showFields.weekhours && job.weekhours" class="mt-4 px-1 inline-block min-w-1/2">
        <font-awesome-icon icon="hourglass" size="lg" />
        <span class="ml-5 align-middle">{{ job.weekhours }} h/Woche</span>
      </div>
      <div v-if="showFields.applications" class="mt-2 inline-block min-w-1/2">
        <font-awesome-icon icon="user-group" />
        <span class="ml-4 align-middle">{{ job.applications }}</span>
      </div>
      <div v-if="showFields.salaryTo && job.salaryTo" class="mt-2 inline-block min-w-1/2">
        <font-awesome-icon icon="coins" />
        <span class="ml-4 align-middle">{{ job.salaryTo }} €</span>
      </div>
      <div v-if="showFields.department && job.department" class="mt-2 inline-block min-w-1/2">
        <font-awesome-icon icon="users" />
        <span class="ml-4 align-middle">{{ job.department }}</span>
      </div>
      <div v-if="showFields.location && job.location" class="mt-2 inline-block min-w-1/2">
        <font-awesome-icon class="ml-1" icon="location-pin" />
        <span class="ml-4 align-middle">{{ job.location }}</span>
      </div>
      
      <div v-if="showFields.portal && job.public && (job.linktype=='applyhr'||job.link)" class="mt-2 inline-block min-w-1/2">
        <font-awesome-icon class="ml-1" icon="earth-americas" />
        <a
          target="_blank"
          v-if="this.job.link.startsWith('http')"
          :href="job.link"
          class="underline ml-4 align-middle"
          >{{ portal }}</a
        >
        <span v-else class="ml-3">{{ portal }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      showFields: {
        "showDate": true,
        "weekhours": true,
        "applications": true,
        "portal": true,
        "details": true,
        "department": true,
        "location": true,
        "salaryTo":true
      }
    }
  },
  props: {
    job: Object,
    currentJob: Object,
    fields: Object
  },
  computed: {
    initials: function () {
      let nameparts = this.job.name.split(" ");
      return nameparts[0][0] + nameparts[1][0];
    },
    portal: function () {
      if(this.job.public && this.job.linktype=="applyhr") return "ApplyHR"
      else if (this.job.link.startsWith("http")) {
        if (this.job.link.match(/\.([a-z-]*)\.[a-z]*\//)) {
          let portal = this.job.link.match(/\.([a-z-]*)\.[a-z]*\//)[1];
          return portal.charAt(0).toUpperCase() + portal.slice(1);
        }
      }
      return "-";
    },
  },
  created: function () {
    if (this.fields) {
      Object.keys(this.fields).forEach(field=>{
        this.showFields[field]=this.fields[field]
      })
          }
  },
};
</script>