<template >
  <div
    ref="modalbg"
    class="z-30 center text-center w-full h-full pt-32 p-4 bg-black/50"
    @click="checkClosing($event)"
  >
    <div
      class="
        bg-white
        inline-block
        w-full
        lg:w-1/3
        rounded-3xl
        overflow-y-auto
        p-5
        basis-0
        relative
      "
    >
      <div @click="$emit('closeModal')" class="iconborder-modal z-40">
        <font-awesome-icon size="lg" icon="xmark" class="inborder" />
      </div>
      <span class="w-full text-center font-bold text-lg"
        >{{ kind }} hinzufügen</span
      ><br />
      <div v-if="!file.data">
        <div
          v-if="
            ['Dokument', 'Anschreiben'].includes(kind) && fileList.Anschreiben.length > 0
          "
          class="text-left"
        >
          <span class="w-full text-left font-bold pt-5"
            >Hochgeladene Anschreiben</span
          >
          <div
            v-for="file in fileList.Anschreiben"
            :key="file"
            class="m-1 bg-gray-200 cursor-pointer inline-block p-1 text-sm rounded-lg" @click="addExistingFile(file)"
          >
            <span class="font-bold">{{ file.originalName }}</span>
          </div>
        </div>
        <div
          v-if="
            ['Dokument', 'Lebenslauf'].includes(kind) && fileList.Lebenslauf.length > 0
          "
          class="text-left"
        >
          <span class="w-full text-left font-bold pt-5"
            >Hochgeladene Lebensläufe</span
          >
          <div
            v-for="file in fileList.Lebenslauf"
            :key="file"
            class="m-1 bg-gray-200 cursor-pointer inline-block p-1 text-sm rounded-lg" @click="addExistingFile(file)"
          >
            <span class="font-bold">{{ file.originalName }}</span>
          </div>
        </div>
        <div
          v-if="
            ['Dokument', 'Qualifikation'].includes(kind) && fileList.Qualifikation.length > 0
          "
          class="text-left"
        >
          <span class="w-full text-left font-bold pt-5"
            >Hochgeladene Qualifikationen</span
          >
          <div
            v-for="file in fileList.Qualifikation"
            :key="file"
            class="m-1 bg-gray-200 cursor-pointer inline-block p-1 text-sm rounded-lg" @click="addExistingFile(file)"
          >
            <span class="font-bold">{{ file.originalName }}</span>
          </div>
        </div>
        <div
          v-if="
            ['Dokument', 'Sonstiges'].includes(kind) && fileList.Sonstiges.length > 0
          "
          class="text-left"
        >
          <span class="w-full text-left font-bold pt-5"
            >Hochgeladene Sonstige Dokumente</span
          >
          <div
            v-for="file in fileList.Sonstiges"
            :key="file"
            class="m-1 bg-gray-200 cursor-pointer inline-block p-1 text-sm rounded-lg" @click="addExistingFile(file)"
          >
            <span class="font-bold">{{ file.originalName }}</span>
          </div>
        </div>
      </div>
      <span class="w-full text-left font-bold pt-5">Neues Dokument</span>
      <input
        ref="input"
        v-show="!file.data"
        class="w-full"
        id="fileinput"
        type="file"
        accept=".pdf"
        @change="prepareUploadFile()"
      />
      <div v-show="file.data" >
        <input
          v-model="file.name"
          placeholder="Dateiname"
          class="border-b border-b-black w-5/6"
        />
        <div class="inline-block" @click="$refs.input.click()">
          <font-awesome-icon
            size="lg"
            icon="xmark"
            class="text-3xl ml-1 cursor-pointer inline-block"
            style="vertical-align: middle"
          />
        </div>
      </div>
      <br />
      <div v-if="file.data" v-bind:class="[typeerror ? 'text-red-500' : '']">
        <span
          v-bind:class="[
            typeerror ? 'text-red-500' : '',
            ['w-full text-left font-bold'],
          ]"
          >Dokumententyp</span
        >
        <select
          v-model="newKind"
          class="bg-inherit focus:outline-none pr-3"
          @change="typeerror = false"
        >
          <option class="" value="">Bitte auswählen</option>
          <option value="Anschreiben">Anschreiben</option>
          <option value="Lebenslauf">Lebenslauf</option>
          <option value="Qualifikationen">Qualifikationen</option>
          <option value="Sonstiges">Sonstiges</option>
        </select>
      </div>
      <button v-if="file.data" @click="uploadFile()" class="mt-4">
        Hochladen
      </button>
    </div>
  </div>
</template>

<script>

import Encryption from "@/Encryption";
export default {
  props: {
    kind: String,
    files: Object,
  },
  emits: ["addFile","closeModal"],
  data() {
    return { 
      file: {
        originalName: "",
        name: "",
      },
      newKind: "",
      typeerror: false,
      fileList: {
        "Anschreiben":[],
        "Lebenslauf": [],
        "Qualifikation":[],
        "Sonstiges":[],
        "Dokument":[]
      },
    };
  },
  methods: {
    checkClosing(event) {
      if (event.target == this.$refs.modalbg) {
        this.$emit("closeModal");
      }
    },
    addExistingFile(file) {
      this.$emit("addFile",file);
    },
    uploadFile() {
      if (this.newKind == "") {
        this.typeerror = true;
        return;
      }
      if (!this.file.data) return;

      this.file.documentType = this.newKind;
      this.file.type = this.newKind;
      this.$emit("addFile",this.file);
    },
    prepareUploadFile() {
      let vm = this;
      let fr = new FileReader();
      document.getElementById("fileinput").files.forEach((file) => {
        fr.readAsArrayBuffer(file);
        fr.onload = function () {
          var data = fr.result;
          vm.file = {
            data: Encryption.arrayBufferToBase64(data),
            originalName: file.name,
            name: file.name,
            documentType: vm.kind,
          };
          if (vm.kind != "Dokument") {
            vm.$emit("addFile",vm.file);
          }
        };
      });
    },
  },
  created: function () {
    if(this.files && this.files.Anschreiben){
      this.fileList=this.files
    }
    setTimeout(() => {
      if (!this.files || this.files[this.kind].length == 0) {
        document.getElementById("fileinput").click();
      }
    }, 100);
  },
};
</script>

<style scoped>
</style>
